<template>
    <v-container class="about-us">
        <v-row>
            <v-col md="4" sm="12" cols="12" v-for="item in about" v-bind:key="item.icon">
                <v-card outlined class="about-us-card text-center">
                    <v-icon color="secondary" x-large>{{item.icon}}</v-icon>
                    <v-card-text v-html="item.text"/>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                about: [
                    {
                        icon: "mdi-cloud-check",
                        text: "We are a passionate team of software engineers with a multidisciplinary background, ready to <mark class=\"highlight\">bring your business ideas to life</mark>, and support you on the way"
                    },
                    {
                        icon: "mdi-rocket",
                        text: "<mark class=\"highlight\">Boost your productivity</mark>, delegate the software design and development keeping control and overview of the feature implementation"
                    },
                    {
                        icon: "mdi-chat-processing",
                        text: "We want to be part of your team, bring transparency in <mark class=\"highlight\">communication</mark>, providing constant <mark class=\"highlight\">feedback</mark> during the whole development cycle"
                    }
                ]
            }
        }
    }
</script>

<style scoped>
    .about-us-card {
        border: 0px !important;
    }
</style>

