<template>
    <v-container class="home">
        <v-img
                :src="require('../assets/logo.svg')"
                :height="getLogoHeight"
                contain
                class="mb-8"
        />
        <h2 class="ma-2">Develop <mark class="highlight">smart</mark> applications in the <mark class="highlight">cloud</mark></h2>
    </v-container>
</template>

<script>
    export default {
        computed: {
            getLogoHeight(){
                if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
                    return 60
                }

                return 100
            }
        }
    }
</script>