<template>
  <v-app id="app">
    <v-container fill-height class="px-sm-10">
      <v-layout class="text-center" row wrap align-center>
        <v-flex>
          <Home/>
          <AboutUs/>
          <ContactUs/>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';

export default {
  name: 'App',

  components: {
    Home,
    AboutUs,
    ContactUs,
  },
};
</script>

<style>
  mark.highlight {
    color: var(--v-primary-base);
    background: transparent;
  }
</style>
